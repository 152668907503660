/*
 * @Author: Vir
 * @Date: 2021-03-18 16:26:35
 * @Last Modified by: Vir
 * @Last Modified time: 2022-03-16 15:56:48
 */

import { SearchEngine } from './types';

// 搜索引擎模板数据
export const WebsiteEngineTemplete: SearchEngine = {
  _id: '',
  name: '',
  value: '',
  href: '',
  icon: '',
  isShow: true,
  isSelected: false,
  count: 0,
  sort: -1,
  classifyId: '',
  userId: '',
  isDefault: false,
};

export default [
  {
    _id: '4723475e274156e87c5de50648af6e84',
    name: 'F搜',
    value: 'fsou',
    href: 'https://fsoufsou.com/search?q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: true,
    count: 100,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 0,
  },
  {
    _id: '4de5457415f1429ea318f8b112a59a6c',
    name: '谷歌',
    value: 'google',
    href: 'https://google.mirrors.pw/search?q=',
    sugurl:
      'https://suggestqueries.google.com/complete/search?client=youtube&q=#content#&jsonp=window.google.ac.h',
    icon: './img/engineLogo/google.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 0,
  },
  {
    _id: '755551cec9054314a50e74ac9130c34b',
    name: '百度',
    value: 'baidu',
    href: 'https://www.baidu.com/s?wd=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/baidu.svg',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 1,
  },
  {
    _id: 'e9275210f149443b9d554a59861887c8',
    name: '搜狗',
    value: 'sougou',
    href: 'https://www.sogou.com/web?query=',
    sugurl: 'https://www.sogou.com/suggnew/ajajjson?type=web&key=#content#',
    icon: './img/engineLogo/sougou.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 0,
  },
  {
    _id: 'ee4b853b01cd4c9bb3fdcb814c13b298',
    name: '好搜',
    value: '好搜',
    href: 'https://www.so.com/s?ie=utf-8&fr=hao_360so&src=home_hao_360so&q=',
    sugurl:
      'https://sug.so.360.cn/suggest?encodein=utf-8&encodeout=utf-8&format=json&word=#content#&callback=window.so.sug',
    icon: './img/engineLogo/so360.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 0,
  },
  {
    _id: 'ace54031b5bb4b4693e4ffc4cc304199',
    name: 'Yandex',
    value: 'yandex',
    href: 'https://yandex.com/search/?text=',
    sugurl:
      'https://quark.sm.cn/api/quark_sug?q=#content#&callback=window.quark.sug',
    icon: './img/engineLogo/quark.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 0,
  },
  {
    _id: '3176606942b5445a913c099aeac9ddb0',
    name: '必应',
    value: 'bing',
    href: 'https://cn.bing.com/search?q=',
    sugurl:
      'https://api.bing.com/qsonhs.aspx?type=cb&q=#content#&cb=window.bing.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 0,
  },
  {
    _id: '73d8759e33ddd2bccd38a0c78a302775',
    name: '知网',
    value: 'cnki',
    href: 'https://scholar.cnki.net/home/search?sw=1&sw-input=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '844663c2786b749c190f637e874002d6',
    sort: 0,
  },
  {
    _id: '6dca010a5e89ae97ad5e5fe15a9c60d3',
    name: 'KGO搜索',
    value: 'ckcest',
    href: 'https://www.ckcest.cn/default/es3/search?page=kgoes&dbtypecode=type_all&dbid=1000&keyword=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '844663c2786b749c190f637e874002d6',
    sort: 0,
  },
  {
    _id: '6dca010a5e89ae97ad5e5fe15a9c60d3',
    name: '谷歌学术',
    value: 'gscholar',
    href: 'https://scholar.google.com.hk/scholar?hl=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '844663c2786b749c190f637e874002d6',
    sort: 0,
  },
  {
    _id: '23ac24d7e589e0a4d4e84a8f2527afb1',
    name: 'Scihub',
    value: 'scihub',
    href: 'https://x.sci-hub.org.cn//scholar?hl=zh-TW&q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '844663c2786b749c190f637e874002d6',
    sort: 0,
  },
  {
    _id: '6f2f20954a28daae81a9696f193ec23f',
    name: '微软学术',
    value: 'msscholar',
    href: 'https://www.bing.com/academic/search?q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '844663c2786b749c190f637e874002d6',
    sort: 0,
  },
  {
    _id: '23ac24d7e589e0a4d4e84a8f2527afb1',
    name: 'Semantics',
    value: 'semantics',
    href: 'https://www.semanticscholar.org/search?q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '844663c2786b749c190f637e874002d6',
    sort: 0,
  },
  {
    _id: '26f148e0dd800bd1084f3e543fe00a90',
    name: 'Github',
    value: 'github',
    href: 'https://github.com/search?q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '2a6454411f8434df7e6bac0c2c669001',
    sort: 0,
  },
  {
    _id: '73d8759e33ddd2bccd38a0c78a302775',
    name: '开发者搜索',
    value: 'bdkaifa',
    href: 'https://kaifa.baidu.com/searchPage?wd=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '2a6454411f8434df7e6bac0c2c669001',
    sort: 0,
  },
  {
    _id: '73d8759e33ddd2bccd38a0c78a302775',
    name: 'Goobe搜索',
    value: 'goobe',
    href: 'https://goobe.io/search.aspx?k=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '2a6454411f8434df7e6bac0c2c669001',
    sort: 0,
  },
  {
    _id: '6dca010a5e89ae97ad5e5fe15a9c60d3',
    name: 'CSDN如意',
    value: 'csdn',
    href: 'https://devbit.csdn.net/search?keyword=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '2a6454411f8434df7e6bac0c2c669001',
    sort: 0,
  },
  {
    _id: '6f2f20954a28daae81a9696f193ec23f',
    name: '思否',
    value: 'segmentfault',
    href: 'https://segmentfault.com/search?q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '2a6454411f8434df7e6bac0c2c669001',
    sort: 0,
  },
  {
    _id: '23ac24d7e589e0a4d4e84a8f2527afb1',
    name: '千帆网盘',
    value: 'qianfan',
    href: 'https://pan.qianfan.app/search/?pan=all&q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '22a64e0b0ab5eebef8672e967c070f37',
    sort: 0,
  },
  {
    _id: 'f5be72d9f20fbd1524d5cacd164f8637',
    name: '茶杯狐',
    value: 'cupfox',
    href: 'https://www.cupfox.com/search?key=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '22a64e0b0ab5eebef8672e967c070f37',
    sort: 0,
  },
  {
    _id: 'bd000c1d6b0b2932d0eb0363eb09b780',
    name: 'Libgen',
    value: 'libgen',
    href: 'https://libgen.gs/index.php?req=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '22a64e0b0ab5eebef8672e967c070f37',
    sort: 0,
  },
  {
    _id: '33400e4b72655e808687a7ab4d67ed58',
    name: 'Z-lib',
    value: 'zlib',
    href: 'https://zh.b-ok.asia/s/',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '22a64e0b0ab5eebef8672e967c070f37',
    sort: 0,
  },
  {
    _id: '8233bae5b3bbaae887f14fa3a2d416db',
    name: 'proletarian',
    value: 'proletarian',
    href: 'http://45.32.41.3/searchResult.php?query=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: '22a64e0b0ab5eebef8672e967c070f37',
    sort: 0,
  },
  {
    _id: '8d4afbbf8b08afc67b1e17b847b04ebc',
    name: '知乎',
    value: 'zhihu',
    href: 'https://www.zhihu.com/search?type=content&q=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 0,
  },
  {
    _id: '64767b8e06c456f0a72291eb29448674',
    name: '微信',
    value: 'weixin',
    href: 'https://weixin.sogou.com/weixin?type=2&query=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 0,
  },
  {
    _id: '64767b8e06c456f0a72291eb29448674',
    name: '头条',
    value: 'toutiao',
    href: 'https://so.toutiao.com/search?dvpf=pc&source=input&keyword=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 0,
  },
  {
    _id: 'cbf1515981c7172db570b90eea283257',
    name: 'bilibili',
    value: 'bilibili',
    href: 'https://search.bilibili.com/all?keyword=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: false,
    count: 1,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 0,
  },
] as SearchEngine[];