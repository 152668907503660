/*
 * @Author: Vir
 * @Date: 2022-01-16 15:39:56
 * @Last Modified by: Vir
 * @Last Modified time: 2022-03-16 15:50:12
 */

import { SearchEngineClassify } from './types';

export const SearchEngineClassifyTemplete: SearchEngineClassify = {
  _id: '',
  name: '',
  value: '',
  sort: -1,
  isShow: true,
  isDefault: false,
  userId: '',
  description: '',
};

export default [
  {
    _id: '6c77e19433a1416d851ef898e0db5707',
    name: '常用',
    value: 'common',
    description: '',
    sort: 0,
    isShow: true,
    isDefault: true,
    userId: '',
  },
  {
    _id: '844663c2786b749c190f637e874002d6',
    name: '学术',
    value: 'scholar',
    description: '',
    sort: 1,
    isShow: true,
    isDefault: false,
    userId: '',
  },
  {
    _id: '2a6454411f8434df7e6bac0c2c669001',
    name: '编程',
    value: 'coding',
    description: '',
    sort: 2,
    isShow: true,
    isDefault: false,
    userId: '',
  },
  {
    _id: '22a64e0b0ab5eebef8672e967c070f37',
    name: '资源',
    value: 'source',
    description: '',
    sort: 3,
    isShow: true,
    isDefault: false,
    userId: '',
  },
  {
    _id: 'df75e91b979445d0943856b57da93226',
    name: '其他',
    value: 'other',
    description: '',
    sort: 4,
    isShow: true,
    isDefault: false,
    userId: '',
  },
] as SearchEngineClassify[];
